<template>
  <div>
    <div>
      <input v-model="user" placeholder="Username" />
      <input
        v-model="message"
        @keyup.enter="sendMessage"
        placeholder="Type	a message"
      />
    </div>
    <div>
      <div v-for="(msg, index) in messages" :key="index">
        {{ msg.user }}: {{ msg.text }}
      </div>
    </div>
    <v-btn @click="CloseSocket()" >test</v-btn>
  </div>
</template>
<script>
import * as signalR from "@microsoft/signalr";
export default {
  data:() =>({
    connection:null,
    user:null,
    messages:null,
    message:null,
  }),
  mounted() {
    // สร้างการเชื่อมต่อกับ SignalR Hub 
    // const connection = new signalR.HubConnectionBuilder()
    //   .withUrl("https://localhost:5001/oasysnoti", {
    //     skipNegotiation: true, // ข้ามการต่อรอง
    //     transport: signalR.HttpTransportType.WebSockets // ใช้ WebSockets โดยตรง
    //   })
    //   .build();

    // // ตั้งค่าการฟัง event จาก SignalR Hub
    // connection.on("ReceiveWebhookNotification", (data) => {
    //   console.log('Received data:', data);
    // });

    // // เริ่มการเชื่อมต่อ
    // connection.start()
    //   .then(() => console.log('Connected to SignalR Hub!'))
    //   .catch(err => {
    //     console.error('Connection failed: ', err)
    //     console.log('Connection failed');
    //     // this.reConnectSocket()
    //   });

    // connection.onclose(() => {
    //   console.log('re connect');
    //   // this.reConnectSocket()
    // });

  },
  created() {
    this.connection = new WebSocket("wss://25nfmzc76e.execute-api.ap-southeast-1.amazonaws.com/production")

    this.connection.onmessage = function(event) {
      console.log(event);
    }
    this.connection.onclose = () => {
      console.log('WebSocket connection closed!');
    };
    this.connection.onopen = function(event) { 
      console.log(event);
      console.log("Successfully connected to the echo websocket server...")
    }

    //   this.connection = new signalR.HubConnectionBuilder()
    //     .withUrl("https://dev-express.packhai-api-88.com/oasysnoti", {
    //       skipNegotiation: true, // ข้ามการต่อรอง
    //       transport: signalR.HttpTransportType.WebSockets // ใช้ WebSockets โดยตรง
    //     })
    //     .build();
    //      // ตั้งค่าการฟัง event จาก SignalR Hub
    // this.connection.on("ReceiveWebhookNotification", (data) => {
    //   console.log('Received data:', data);
    // });
    //    // เริ่มการเชื่อมต่อ
    // this.connection.start()
    //   .then(() => console.log('Connected to SignalR Hub!'))
    //   .catch(err => {
    //     console.error('Connection failed: ', err)
    //     console.log('Connection failed');
    //     // this.reConnectSocket()
    //   });
    // this.connection.onclose(() => {
    //   console.log('re connect');
    //   // this.reConnectSocket()
    // });
  },
  methods: {
    CloseSocket(){
      this.connection.close();
    }
    // async reConnectSocket() {
    //   const connection = new signalR.HubConnectionBuilder()
    //     .withUrl("https://dev-express.packhai-api-88.com/notifications", {
    //       skipNegotiation: true, // ข้ามการต่อรอง
    //       transport: signalR.HttpTransportType.WebSockets // ใช้ WebSockets โดยตรง
    //     })
    //     .build();

    //   // ตั้งค่าการฟัง event จาก SignalR Hub
    //   connection.on("ReceiveWebhookNotification", (data) => {
    //     console.log('Received data:', data);
    //   });

    //   // เริ่มการเชื่อมต่อ
    //   connection.start()
    //     .then(() => {})
    //     .catch(err => {
    //       this.reConnectSocket()
    //     });

    //   connection.onclose(() => {
    //     this.reConnectSocket()
    //   });
    // }
  }

  // created() {
  //   this.Connection = new signalR.HubConnectionBuilder().withUrl("/ts").build();

  //   this.connection.on("ReceiveMessage", (user, message) => {
  //     this.messages.push({ user, text: message });
  //   });
  //   this.connection.start().catch((err) => console.Error(err));
  // },
  // methods: {
  //   sendMessage() {
  //     this.connection.invoke("SendMessage", this.user, this.Message);
  //     this.Message = "";
  //   },
  // },
};
</script>
